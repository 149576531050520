<!--
 * @Description: 角色权限新增、修改
 * @Author: 前胡
 * @LastEditors: 胡椒
 * @Date: 2020-03-13 10:56:09
 * @LastEditTime: 2020-08-24 14:55:54
 -->
 <template>
  <div class="role-modal">
    <a-drawer :title="title" width="850px" placement="right" @close="handleHideThisModal" :visible="roleModalVisible">
      <a-form :form="roleForm">
        <a-form-item label="角色名称" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-input style="width:350px;" v-decorator="['role_name', { rules: [{ required: true, message: '请输入角色名称' },{
            max:40,
            message: '最多40个字',
          }] }]" />
        </a-form-item>
        <a-form-item label="角色权限" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-tree checkable showIcon @expand="onExpandRoleTree" :expandedKeys="expandedKeys" :autoExpandParent="true" v-model="right_codes" :selectedKeys="selectedKeys" :treeData="RoleTree">
            <a-icon type="layout" slot="layout" />
            <a-icon type="profile" slot="profile" />
            <a-icon type="thunderbolt" slot="thunderbolt" />
          </a-tree>
        </a-form-item>
      </a-form>
      <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
        <a-button class="mr-20" @click="handleHideThisModal">取消</a-button>
        <a-button type="primary" html-type="submit" @click="handleCreateEditAccountData" :loading="isPosting">保存</a-button>
      </a-form-item>
    </a-drawer>
  </div>
</template>
<script>
import { postRole, patchRole } from "@/service/setting";
import { RoleTree } from "@/const/role";

export default {
  name: "RoleDrawer",
  props: {
    data: {
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      RoleTree,
      autoExpandParent: true,
      expandedKeys: [],
      checkedKeys: [],
      selectedKeys: [],
      roleForm: this.$form.createForm(this),
      roleModalVisible: true,
      isPosting: false,
      right_codes: [],
      role_name: "",
    };
  },
  computed: {
    title: function () {
      if (this.data && this.data.role_id) {
        return "编辑角色";
      } else {
        return "添加角色";
      }
    },
  },
  mounted() {
    if (this.data && this.data.role_id) {
      const that = this;
      const postData = JSON.parse(JSON.stringify(this.data));
      this.right_codes = this.filterRightCodes(postData.right_codes);
      this.role_name = postData.role_name;
      setTimeout(function () {
        that.refillData();
      }, 0);
    }
  },
  methods: {
    // 权限列表过滤
    filterRightCodes(right_codes) {
      // 获取roletree的数据，并且过滤掉有children的key
      const tree = JSON.parse(JSON.stringify(RoleTree));
      const noChildRoles = this.filterRolesWithSubChildren(tree);
      const finalRightCodes = noChildRoles.filter((i) =>
        right_codes.includes(i)
      );
      return finalRightCodes;
    },
    // 过滤掉有children权限的key
    filterRolesWithSubChildren(tree, result = []) {
      let rs = result;
      const roleTreeClone = JSON.parse(JSON.stringify(tree));
      roleTreeClone.forEach((i) => {
        if (i.children && i.children.length) {
          return this.filterRolesWithSubChildren(i.children, rs);
        } else {
          result = rs.push(i.key);
        }
      });
      return result;
    },
    // 回填数据
    refillData() {
      this.$nextTick(() => {
        this.roleForm.setFieldsValue({
          right_codes: this.right_codes,
          role_name: this.role_name,
        });
      });
    },
    // 新增
    handleCreateEditAccountData() {
      this.roleForm.validateFields(async (formErr, values) => {
        if (!formErr) {
          if (this.right_codes && this.right_codes.length === 0) {
            this.$message.error("请选择权限");
            return false;
          }
          // if(this.right_codes && this.right_codes.includes('goods_manage_taobao_putaway')  && this.right_codes.includes('goods_manage_taobao_list')===false ) {
          //   this.$message.error('淘宝商品上架功能依赖于淘宝商品列表权限，请勾选淘宝商品列表权限')
          //   return false
          // }
          this.isPosting = true;
          if (this.data && this.data.role_id) {
            // 修改
            const { err, res } = await patchRole({
              right_codes: this.right_codes,
              role_name: values.role_name,
              role_id: this.data.role_id,
            });
            if (!err) {
              if (res.success) {
                this.$message.success(res.message);
                this.handleHideThisModal(0);
                await this.$store.dispatch("getRights");
              }
            }
          } else {
            // 新增
            const { err, res } = await postRole({
              right_codes: this.right_codes,
              role_name: values.role_name,
            });
            if (!err) {
              if (res.success) {
                this.$message.success(res.message);
                this.handleHideThisModal(1);
                await this.$store.dispatch("getRights");
              }
            }
          }
          this.isPosting = false;
        }
      });
    },
    // 隐藏modal
    handleHideThisModal(page = -1) {
      this.$emit("callback", page);
    },
    // 展开树
    onExpandRoleTree(expandedKeys) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    // 选择节点
    onCheck(checkedKeys) {
      this.checkedKeys = checkedKeys;
    },
  },
};
</script>
<style lang="less" scoped>
.mr-20 {
  margin-right: 20px;
}
</style>
