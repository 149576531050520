<!--
 * @Author: 前胡
 * @LastEditors: 胡椒
 * @Date: 2020-03-03 17:30:54
 * @LastEditTime: 2020-08-08 14:56:35
 * @Description: 角色管理
 -->
<template>
  <div id="roleManager">
    <RoleSearchBar
    v-auth="'role_manage_list'"
      @callback="accountSearchCallback"
      :searchParams="searchParams"
      :loading="listLoading"
    />
    <div class="option-btns">
      <a-button
       v-auth="'role_manage_add_role'"
        type="primary"
        class="option-btn"
        html-type="submit"
        @click="handleShowRoleModal({})"
      >添加角色</a-button>
    </div>
    <a-table
      :scroll="{ y: tableScrollY }"
      v-auth="'role_manage_list'"
      :rowKey="record => record.role_id"
      :columns="roleColumns"
      :dataSource="list"
      :pagination="pagination"
      :loading="listLoading"
      :locale="{ emptyText: '暂无角色' }"
      @change="handleTableChange"
    >
      <template slot="operation" slot-scope="record">
        <a-button size="small" class="mr-10" type="primary" @click="handleShowRoleModal(record)" v-auth="'role_manage_modify_role'">编辑</a-button>
        <a-button size="small" class="mr-10" @click="handleShowRoleDetailModal(record)">查看</a-button>
        <a-button size="small" class="mr-10" @click="handleConfirmDelRole(record)" v-auth="'role_manage_del_role'">删除</a-button>
      </template>
    </a-table>
    <!--添加修改角色-->
    <RoleDrawer :data="tempRoleData" v-if="roleModalVisiable" @callback="hideRoleModal" />
    <!--查看角色-->
    <RoleDetailDrawer :data="tempRoleData" v-if="roleDetailModalVisiable" @callback="hideRoleDetailModal" />
  </div>
</template>

<script>
import { getRoleList, delRole } from "@/service/setting";
import RoleSearchBar from "@/components/admin/setting/role/role-search-bar";
import RoleDrawer from "@/components/admin/setting/role/drawer-role";
import RoleDetailDrawer from "@/components/admin/setting/role/drawer-role-detail";
import { checkPer } from "@/utils/index";

// 角色列表
const roleColumns = [
  {
    title: "角色名称",
    dataIndex: "role_name",
    align: "left",
    width: 400
  },
  {
    title: "创建时间",
    width: 180,
    dataIndex: "create_time",
    align: "center"
  },
  {
    title: "操作",
    align: "center",
    width: 220,
    scopedSlots: { customRender: "operation" }
  }
];

export default {
  name: "RoleSetting",
  components: {
    RoleSearchBar,
    RoleDrawer,
    RoleDetailDrawer
  },
  data() {
    return {
      tableScrollY: 600,
      form: this.$form.createForm(this),
      roleColumns,
      // 添加编辑账号弹层状态
      roleModalVisiable: false,
      roleDetailModalVisiable: false,
      // 临时账号数据
      tempRoleData: {},
      listLoading: true,
      list: [],
      searchParams: {
        search: "",
      },
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: total =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize)
      }
    };
  },
  created() {
    const windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 325;
  },
  mounted() {
    this.getRoleList();
  },
  methods: {
    // 获取角色列表
    async getRoleList() {
      if (!checkPer("role_manage_list")) {
        this.$message.error("无权限获取列表");
        return false;
      }
      const data = Object.assign(
        { page: this.pagination.current, page_size: this.pagination.pageSize },
        this.searchParams
      );
      this.listLoading = true;
      const { err, res } = await getRoleList(data);
      this.listLoading = false;
      if (!err) {
        if (res.success) {
          const pagination = { ...this.pagination };
          this.list = res.data.results;
          pagination.total = res.data.count;
          this.pagination = pagination;
        } else {
          this.$message.warning(res.message);
        }
      }
    },
    // 表格分页
    handleTableChange(pagination) {
      if (pagination) this.pagination.current = pagination.current;
      this.getRoleList();
    },
    // 显示添加修改角色
    handleShowRoleModal(data) {
      this.tempRoleData = JSON.parse(JSON.stringify(data));
      this.roleModalVisiable = true;
    },
    // 显示角色详情弹层
    handleShowRoleDetailModal(data){
      this.tempRoleData = JSON.parse(JSON.stringify(data));
      this.roleDetailModalVisiable = true;
    },
    // 搜索查询按钮
    accountSearchCallback(res) {
      this.pagination.current = 1;
      this.searchParams = Object.assign({}, res);
      this.getRoleList();
    },
    // 隐藏角色drawer
    hideRoleModal(isNeedFresh = -1) {
      this.roleModalVisiable = false;
      if (isNeedFresh === 1) {
        this.pagination.current = 1;
        this.getRoleList();
      }
      if (isNeedFresh === 0) {
        this.getRoleList();
      }
    },
    // 隐藏角色详情drawer
    hideRoleDetailModal() {
      this.roleDetailModalVisiable = false;
    },
    // 删除角色
    handleConfirmDelRole(record) {
      const that = this
      this.$confirm({
        title: "删除角色",
        content: () => `确认要删除名为 ${record.role_name} 的角色?`,
        async onOk() {
          const { err, res } = await delRole({ role_id: record.role_id });
          if (!err) {
            if (res.success) {
              that.$message.success("角色已删除");
              that.getRoleList();
            }
          }
        }
      });
    }
  }
};
</script>
<style lang='less' scoped>
.option-btns {
  margin-bottom: 20px;
  margin-top: 10px;
  .option-btn {
    margin-right: 10px;
  }
}
.mr-10 {
  margin-right: 10px;
}
</style>
