/*
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-03-06 15:15:21
 * @LastEditTime: 2020-03-31 10:39:20
 * @Description: 设置相关api
 */
import axios from './axios'

// 后台用户列表
export const getAccountList = params => {
    return axios.get('/api/operators/v1/admin-user/', {
        params
    })
}

// 新增后台用户
export const postAdminUser = (data) => {
    return axios.post('/api/operators/v1/admin-user/', data)
}

// 编辑后台用户
export const patchAdminUser = (data) => {
    return axios.patch('/api/operators/v1/admin-user/', data)
}

// 重置密码
export const resetPassword = (data) => {
    return axios.patch('/api/operators/v1/admin-user/', data)
}

// 删除后台用户
export const delAdminUser = data => {
    return axios.delete('/api/operators/v1/admin-user/', {data})
}

// 新增角色
export const postRole = (data) => {
    return axios.post('/api/operators/v1/role/', data)
}

// 编辑角色
export const patchRole = (data) => {
    return axios.patch('/api/operators/v1/role/', data)
}

// 角色列表
export const getRoleList = params => {
    return axios.get('/api/operators/v1/role/', {params})
}

// 删除角色
export const delRole = data => {
    return axios.delete('/api/operators/v1/role/', {data})
}