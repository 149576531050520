<!--
 * @Author: 前胡
 * @LastEditors: 胡椒
 * @Date: 2020-03-05 15:14:50
 * @LastEditTime: 2020-08-24 13:55:09
 * @Description: 账号管理搜索条
 -->
<template>
  <a-form class="filter-condition-form" layout="inline" :form="form" @submit="handleFormSubmit">
    <a-form-item>
      <a-input v-model="searchParams.search"  style="width: 240px" placeholder="角色名称" allowClear />
    </a-form-item>
    <a-form-item>
      <a-button type="primary" html-type="submit" :loading="loading">查询</a-button>
    </a-form-item>
  </a-form>
</template>
<script>

export default {
  name: "RoleSearchBar",
  props: {
    searchParams: {
      type: Object,
      default: function() {
        return {};
      }
    },
    loading: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      form: this.$form.createForm(this)
    };
  },
  methods: {
    handleFormSubmit(e) {
      e.preventDefault();
      this.form.validateFields(err => {
        if (!err) {
          this.$emit("callback", this.searchParams);
        }
      });
    },
  }
};
</script>
<style lang='less' scoped>
</style>
