<!--
 * @Description: 查看角色详情
 * @Author: 前胡
 * @LastEditors: 胡椒
 * @Date: 2020-03-13 10:56:09
 * @LastEditTime: 2020-08-24 14:56:34
 -->
 <template>
  <div class="role-modal">
    <a-drawer
      title="查看角色"
      width="850px"
      placement="right"
      @close="handleHideThisModal"
      :visible="roleModalVisible"
    >
      <a-form :form="roleForm">
        <a-form-item
          label="角色名称"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 12 }"
        >{{data.role_name}}</a-form-item>
        <a-form-item label="角色权限" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-tree
            disabled
            checkable
            showIcon
            defaultExpandAll
            @expand="onExpandRoleTree"
            :expandedKeys="expandedKeys"
            :autoExpandParent="true"
            v-model="data.right_codes"
            :selectedKeys="selectedKeys"
            :treeData="RoleTree"
          >
            <a-icon type="layout" slot="layout" />
            <a-icon type="profile" slot="profile" />
            <a-icon type="thunderbolt" slot="thunderbolt" />
          </a-tree>
        </a-form-item>
      </a-form>
    </a-drawer>
  </div>
</template>
<script>
import { RoleTree } from "@/const/role";

export default {
  name: "RoleDetailDrawer",
  props: {
    data: {
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      RoleTree,
      autoExpandParent: true,
      expandedKeys: [],
      checkedKeys: [],
      selectedKeys: [],
      roleForm: this.$form.createForm(this),
      roleModalVisible: true,
      isPosting: false,
      right_codes: [],
      role_name: ""
    };
  },
  methods: {
    // 回填数据
    refillData() {
      this.$nextTick(() => {
        this.roleForm.setFieldsValue({
          right_codes: this.right_codes,
          role_name: this.role_name
        });
      });
    },
    // 隐藏modal
    handleHideThisModal(page = -1) {
      this.$emit("callback", page);
    },
    // 展开树
    onExpandRoleTree(expandedKeys) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = true;
    },
    // 选择节点
    onCheck(checkedKeys) {
      this.checkedKeys = checkedKeys;
    }
  }
};
</script>
<style lang="less" scoped>
.mr-20 {
  margin-right: 20px;
}
</style>
